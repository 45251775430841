<template>
  <div>
    <div class="columns">
      <div class="column is-one-third">
        <card-component
          title="1. Select the dataset and set options"
          class="tile is-child"
        >
          <validation-observer v-slot="{ handleSubmit }">
            <validation-provider
              v-slot="{ errors }"
              :rules= {required:true}
              name="dataset"
            >
              <dataset-select
                label="Select a dataset"
                :dataset.sync="dataset"
                v-model="dataset"
                :withVarname="false"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
                icon="table"
                pack="fa"
                :minNumericVariable=1
                expanded
              />
            </validation-provider>
            <hr />
            <b-field label="Type of heatmap">
              <b-radio
                v-model="typePlot"
                native-value="heatmap"
              >
                Heatmap (colored matrix)
              </b-radio>
            </b-field>
            <b-radio
              v-model="typePlot"
              native-value="dotplot"
            >
              Dotplot (proportional size and color)
            </b-radio>
            <hr />
            <b-field label="Dendrogram options">
              <b-switch v-model="dendoRows">
                Compute the dendrogram on individuals (rows) and sort them.
              </b-switch>
            </b-field>
            <b-switch v-model="dendoCols">
              Compute the dendrogram on variables (columns) and sort them.
            </b-switch>
            <hr />
            <b-field label="Interactive graph">
              <b-switch v-model="interactive">
                Compute interactive graph
                <font size="2"><p style="color:red;">Warning! For large datasets, it can be very long!</p></font>
              </b-switch>
            </b-field>
            <hr />
            <dataset-select
              label="Variable to identify individuals with colors (optional)"
              :dataset.sync="datasetRowColors"
              :varname.sync="variableRowColors"
              :type="{ 'is-danger': false }"
              :message="[]"
              :vartype="'quali'"
              :minCatVariable="1"
              :minlevel="2"
              :alldistinct="'no'"
              labeltooltip="Non-constant and non 'all distincts' categorical variables"
              v-model="variableRowColors"
              icon="xaxis"
              @select="option => selectVarname(option)" />
            <hr />
            <div class="has-text-centered">
              <b-button rounded
                @click="handleSubmit(run)"
                class="is-primary button-shadow"
              >
                <span class="icon is-small"><i class="fa fa-search"></i></span>
                <span> Create heatmap </span>
              </b-button>
            </div>
          </validation-observer>
        </card-component>
      </div>
      <div class="column">
        <card-component title="2. Heatmap" />
        <error :type='"notifications"' />
        <card-no-result v-show="!resultsLoaded">
          <strong>Important note</strong>: Heatmap is performed with raw values
          (data are not scaled to unit variance before they are processed).
          <strong>If you want to display a heatmap on scaled data</strong>, go
          to the menu “Edit / Dataset edition” and scale your dataset(s) before.
        </card-no-result>
        <card-component v-if="resultsLoaded">
          <plotly
            :height="700"
            :data="graphical"
          ></plotly>
          <nav class="level" >
            <div class="level-left" />
            <div class="buttons level-right">
              <r-save-for-report
                label="Save for report"
                :params="params"
              />
            </div>
          </nav>
        </card-component>
        <card-component v-if="png">
          <png :data=png />
          <nav class="level">
            <div class="level-left" />
            <div class="buttons level-right">
              <r-save-for-report
                label="Save for report"
                :params="params"
              />
            </div>
          </nav>
        </card-component>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import Plotly from '@/components/rresults/Plotly.vue'
import apiService from '@/services/apiService'
import { useWorkspaceStore } from '@/stores/workspacestore'

export default {
  name: 'RHeatmap',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'dataset-select': () => import('@/components/ui/DatasetSelect.vue'),
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'png': () => import('@/components/rresults/PNG.vue'),
    'plotly': Plotly,
    'error': () => import('@/components/ui/Error.vue'),
    'r-save-for-report': () => import('@/components/RSaveForReport.vue')
  },
  data () {
    return {
      graphical: null,
      dataset: null,
      dendoRows: false,
      dendoCols: false,
      datasetRowColors: null,
      variableRowColors: '',
      typePlot: 'heatmap',
      interactive: false,
      png: null,
      resultsLoaded: false
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  computed: {
    params: function () {
      let params = {
        'func_name': 'r_heatmap',
        'dataset': this.dataset,
        'dendoRows': this.dendoRows,
        'dendoCols': this.dendoCols,
        'datasetRowColors': this.datasetRowColors,
        'variableRowColors': this.variableRowColors,
        'typePlot': this.typePlot,
        'interactive': this.interactive
      }
      return params
    }
  },
  mounted () {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })
    this.workspaceStore.getWorkflow()
      .then(() => {
        loadingComponent.close()
      })
      .catch(() => {
        loadingComponent.close()
      })
  },
  methods: {
    selectVarname: function (value) {
      this.variableRowColors = value.name
    },
    run: function () {
      this.resultsLoaded = false
      this.png = ''
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      apiService.runRFunction({
        'func_name': 'r_heatmap',
        'dataset': this.dataset,
        'dendoRows': this.dendoRows,
        'dendoCols': this.dendoCols,
        'datasetRowColors': this.datasetRowColors,
        'variableRowColors': this.variableRowColors,
        'typePlot': this.typePlot,
        'interactive': this.interactive
      })
        .then(data => {
          this.graphical = null
          for (let key of Object.keys(data)) {
            let jresult = data[key]
            if (jresult.type === 'PlotLy') {
              this.graphical = jresult
            } else if (jresult.type === 'png') {
              this.png = jresult
            }
          }
          loadingComponent.close()
          this.resultsLoaded = true
        })
        .catch(() => {
          loadingComponent.close()
          this.resultsLoaded = true
        })
    }
  }
}
</script>
