<template>
  <div ref="plotly" :style="{ height: height, width: width }"></div>
</template>

<script>
import Plotly from 'plotly.js'

export default {
  name: 'Plotly',
  props: {
    data: {
      type: Object,
      default: null
    },
    staticPlot: {
      type: Boolean,
      default: false
    },
    width: {
      default: null
    },
    height: {
      default: 570
    },
    onlyGraphic: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    indata: function () {
      let indata = JSON.parse(JSON.stringify(this.data.data.data))
      for (let serie of indata) {
        if (typeof serie.x === 'number') {
          serie.x = [serie.x]
          serie.y = [serie.y]
          serie.width = [serie.width]
          serie.base = [serie.base]
          serie.text = [serie.text]
        }
      }
      return indata
    },
    layout: function () {
      let layout = JSON.parse(JSON.stringify(this.data.data.layout))
      if (this.width) {
        layout.width = this.width
      }
      if (this.height) {
        layout.height = this.height
      }
      if (this.onlyGraphic) {
        layout.title = {}
        layout.showlegend = false
        layout.legend = {}
        layout.xaxis = {}
        layout.yaxis = {}
        layout.margin = {
          l: 0,
          r: 0,
          b: 0,
          t: 0,
          pad: 0
        }
      }
      return layout
    }
  },
  data: function () {
    return {
      config: {
        responsive: true,
        displayModeBar: true,
        staticPlot: this.staticPlot,
        modeBarButtonsToRemove: [
          'zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d',
          'autoScale2d', 'resetScale2d', 'zoom3d', 'pan3d', 'orbitRotation',
          'tableRotation', 'handleDrag3d', 'resetCameraDefault3d', 'resetCameraLastSave3d',
          'hoverClosest3d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'zoomInGeo',
          'zoomOutGeo', 'resetGeo', 'hoverClosestGeo', 'hoverClosestGl2d', 'hoverClosestPie',
          'toggleHover', 'resetViews', 'sendDataToCloud', 'toggleSpikelines', 'resetViewMapbox'],
        toImageButtonOptions: {
          format: 'png', // one of png, svg, jpeg, webp
          height: 500,
          width: 700,
          scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
        }
      }
    }
  },
  mounted () {
    this.newPlot()
    this.$watch('data', this.newPlot, { deep: true })
  },
  methods: {
    newPlot () {
      Plotly.newPlot(this.$refs.plotly, this.indata, this.layout, this.config)
        .then(gd => {
          gd.on('plotly_click', d => {
            if ('customdata' in d.points[0]) {
              this.$emit('click', d.points[0].customdata)
            }
          })
        })
    }
  }
}
</script>
